/* @flow */
import '../../css/dashboard/CommissionTable.css';
import * as React from 'react';
import { Table } from 'antd';
import {
  formatFullName,
  formatNumberAsMoney,
  formatNumberAsPercentage,
} from '../../utils/formatter';
import DashboardLink from '../shared/DashboardLink.jsx';
import type { ManagerCommission } from '../../models/managers/ManagerCommission';

type Props = $ReadOnly<{
  commissions: ?Array<ManagerCommission>,
  total: ?number,
  payPeriodId: number,
  minScrollHeight?: number,
  includeChannelAverages: boolean,
  includeSDRStatistics: boolean,
  includeOverride: boolean,
  showFooter: boolean,
}>;

ManagerCommissionsTable.defaultProps = {
  minScrollHeight: 240,
};

function ManagerCommissionsTable(props: Props): React.Node {
  const {
    commissions,
    payPeriodId,
    minScrollHeight,
    includeChannelAverages,
    includeSDRStatistics,
    includeOverride,
  } = props;
  const scroll = { y: minScrollHeight };

  return (
    <Table
      bordered
      columns={getColumns(
        payPeriodId,
        includeChannelAverages,
        includeSDRStatistics,
        includeOverride,
      )}
      dataSource={commissions}
      size="small"
      pagination={false}
      scroll={scroll}
      footer={(deals) => getFooter(deals, props)}
      rowKey={(deal) => deal.id}
    />
  );
}

function getColumns(
  payPeriodId: number,
  includeChannelAverages: boolean,
  includeSDRStatistics: boolean,
  includeOverride: boolean,
): any {
  var columns = [
    {
      title: 'Your Team',
      dataIndex: 'teamName',
      editable: false,
      sorter: (a, b) => a.teamName.localeCompare(b.teamName),
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Rep',
      dataIndex: [],
      editable: false,
      render: (commission: ManagerCommission): React.Node => (
        <DashboardLink agentId={commission.repMasterAgentId} payPeriodId={payPeriodId}>
          {formatFullName(commission.repFirstName, commission.repLastName)}
        </DashboardLink>
      ),
      sorter: (a, b) =>
        formatFullName(a.repFirstName, a.repLastName).localeCompare(
          formatFullName(b.repFirstName, b.repLastName),
        ),
      defaultSortOrder: 'ascend',
      width: 250,
      fixed: 'left',
    },
    {
      title: 'Current Team',
      dataIndex: 'currentTeam',
      editable: false,
      sorter: (a, b) => a.currentTeam - b.currentTeam,
      width: 200,
      fixed: 'left',
    },
    {
      title: 'Earned Total',
      dataIndex: 'earnedTotal',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.earnedTotal - b.earnedTotal,
      align: 'right',
      width: 175,
      fixed: 'left',
    },
    {
      title: 'Total Commission',
      dataIndex: 'commissionTotal',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.commissionTotal - b.commissionTotal,
      align: 'right',
      width: 175,
    },
    {
      title: 'Override %',
      dataIndex: 'overridePercentage',
      editable: false,
      render: (value) => formatNumberAsPercentage(value, true, true),
      sorter: (a, b) => a.overridePercentage - b.overridePercentage,
      align: 'right',
      width: 125,
    },
    {
      title: 'Override Contr.',
      dataIndex: 'overrideAmount',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.overrideAmount - b.overrideAmount,
      align: 'right',
      width: 175,
    },
    {
      title: 'DM Per Unit Avg.',
      dataIndex: 'directMailPerUnitCommissionAverage',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.directMailPerUnitCommissionAverage - b.directMailPerUnitCommissionAverage,
      align: 'right',
      width: 175,
    },
    {
      title: 'Digital Per Unit Avg.',
      dataIndex: 'digitalPerUnitCommissionAverage',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.digitalPerUnitCommissionAverage - b.digitalPerUnitCommissionAverage,
      align: 'right',
      width: 175,
    },
    {
      title: 'Channel Bonus',
      dataIndex: 'bonusAmount',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.bonusAmount - b.bonusAmount,
      align: 'right',
      width: 120,
    },
    {
      title: 'Sales',
      dataIndex: 'numberOfSoldDeals',
      editable: false,
      sorter: (a, b) => a.numberOfSoldDeals - b.numberOfSoldDeals,
      align: 'right',
      width: 175,
    },
    {
      title: 'Calls',
      dataIndex: 'numberOfSDRCalls',
      editable: false,
      sorter: (a, b) => a.numberOfSDRCalls - b.numberOfSDRCalls,
      align: 'right',
      width: 175,
    },
    {
      title: 'Transfers',
      dataIndex: 'numberOfSDRTransfers',
      editable: false,
      sorter: (a, b) => a.numberOfSDRTransfers - b.numberOfSDRTransfers,
      align: 'right',
      width: 175,
    },
    {
      title: 'Closing %',
      dataIndex: 'sdrClosingPercentage',
      editable: false,
      render: (value) => formatNumberAsPercentage(value),
      sorter: (a, b) => a.sdrClosingPercentage - b.sdrClosingPercentage,
      align: 'right',
      width: 175,
    },
    {
      title: 'Transfer %',
      dataIndex: 'sdrTransferPercentage',
      editable: false,
      render: (value) => formatNumberAsPercentage(value),
      sorter: (a, b) => a.sdrTransferPercentage - b.sdrTransferPercentage,
      align: 'right',
      width: 175,
    },
    {
      title: 'Sold Deals - DM',
      dataIndex: 'numberOfSoldDealsDirectMail',
      editable: false,
      sorter: (a, b) => a.numberOfSoldDealsDirectMail - b.numberOfSoldDealsDirectMail,
      align: 'right',
      width: 175,
    },
    {
      title: 'Sold Deals - Digital',
      dataIndex: 'numberOfSoldDealsDigital',
      editable: false,
      sorter: (a, b) => a.numberOfSoldDealsDigital - b.numberOfSDRSales,
      align: 'right',
      width: 175,
    },
    {
      title: 'Sold Deals - TV',
      dataIndex: 'numberOfSoldDealsTv',
      editable: false,
      sorter: (a, b) => a.numberOfSoldDealsTv - b.numberOfSoldDealsTv,
      align: 'right',
      width: 175,
    },
    {
      title: 'Calls - DM',
      dataIndex: 'numberOfCallsDirectMail',
      editable: false,
      sorter: (a, b) => a.numberOfCallsDirectMail - b.numberOfCallsDirectMail,
      align: 'right',
      width: 175,
    },
    {
      title: 'Calls - Digital',
      dataIndex: 'numberOfCallsDigital',
      editable: false,
      sorter: (a, b) => a.numberOfCallsDigital - b.numberOfCallsDigital,
      align: 'right',
      width: 175,
    },
    {
      title: 'Calls - TV',
      dataIndex: 'numberOfCallsTv',
      editable: false,
      sorter: (a, b) => a.numberOfCallsTv - b.numberOfCallsTv,
      align: 'right',
      width: 175,
    },
    {
      title: 'Dials - DM',
      dataIndex: 'numberOfDialsDirectMail',
      editable: false,
      sorter: (a, b) => a.numberOfDialsDirectMail - b.numberOfDialsDirectMail,
      align: 'right',
      width: 175,
    },
    {
      title: 'Dials - Digital',
      dataIndex: 'numberOfDialsDigital',
      editable: false,
      sorter: (a, b) => a.numberOfDialsDigital - b.numberOfDialsDigital,
      align: 'right',
      width: 175,
    },
    {
      title: 'Dials - TV',
      dataIndex: 'numberOfDialsTv',
      editable: false,
      sorter: (a, b) => a.numberOfDialsTv - b.numberOfDialsTv,
      align: 'right',
      width: 175,
    },
  ];

  if (!includeOverride) {
    columns = columns.filter(
      (column) =>
        column.dataIndex !== 'overridePercentage' && column.dataIndex !== 'overrideAmount',
    );
  }

  if (!includeChannelAverages) {
    columns = columns.filter(
      (column) =>
        column.dataIndex !== 'directMailPerUnitCommissionAverage' &&
        column.dataIndex !== 'digitalPerUnitCommissionAverage',
    );
  }

  if (!includeSDRStatistics) {
    columns = columns.filter(
      (column) =>
        column.dataIndex !== 'numberOfSDRSales' &&
        column.dataIndex !== 'numberOfSDRCalls' &&
        column.dataIndex !== 'numberOfSDRTransfers' &&
        column.dataIndex !== 'sdrTransferPercentage' &&
        column.dataIndex !== 'sdrClosingPercentage',
    );
  }

  return columns;
}

function getFooter(deals: Array<ManagerCommission>, props: Props): React.Node {
  const { total, showFooter } = props;
  const formattedTotal = formatNumberAsMoney(total, true);

  if (!showFooter) {
    return <></>;
  }

  return (
    <div className="commission-table-footer">
      <div className="commission-table-footer-cell commission-table-footer-cell-label">
        Total Earned this Period:
      </div>
      <div className="commission-table-footer-cell commission-table-footer-cell-value">
        {formattedTotal}
      </div>
    </div>
  );
}

export default ManagerCommissionsTable;
