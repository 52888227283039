/* @flow */
import '../../css/dashboard/BonusSection.css';
import * as React from 'react';
import { BonusType } from '../../enums/BonusTypeEnum';
import { Card, Col, Empty, Progress, Row } from 'antd';
import { DealTypes } from '../../enums/DealTypeEnum';
import { RoleEnum } from '../../enums/RoleEnum';
import { formatBonusType, formatCallDirection, formatDealType } from '../../utils/enumFormatter';
import {
  formatDateAsShortDate,
  formatNumberAsMoney,
  formatNumberAsPercentage,
  formatNumberAsPercentageWithDecimals,
  formatNumberForLocale,
  truncateNumber,
} from '../../utils/formatter';
import type { Bonus } from '../../models/bonuses/Bonus';
import type { ClosingRatioBonus } from '../../models/bonuses/ClosingRatioBonus';
import type { DialsPerSaleBonus } from '../../models/bonuses/DialsPerSaleBonus';
import type { FirstPayBonus } from '../../models/bonuses/FirstPayBonus';
import type { ManagerRepCommissionTargetBonus } from '../../models/bonuses/ManagerRepCommissionTargetBonus';
import type { ManagerSalesClosingRatioBonus } from '../../models/bonuses/ManagerSalesClosingRatioBonus';
import type { ManagerSalesDialsPerSaleBonus } from '../../models/bonuses/ManagerSalesDialsPerSaleBonus';
import type { ManagerSalesPerUnitTargetBonus } from '../../models/bonuses/ManagerSalesPerUnitTargetBonus';
import type { SDRTeamPerformanceBonus } from '../../models/bonuses/SDRTeamPerformanceBonus';
import type { SaveRatioBonus } from '../../models/bonuses/SaveRatioBonus';
import type { SecondPayPlusBonus } from '../../models/bonuses/SecondPayPlusBonus';

type Props = $ReadOnly<{
  bonuses: ?Array<Bonus>,
  showSalesBonuses: boolean,
  showRetentionBonuses: boolean,
  showPerUnitTargetBonuses: boolean,
  showSDRBonuses: boolean,
  agentRole: RoleEnum,
  showBillingSpecialistBonuses: boolean,
}>;

function BonusSection(props: Props): React.Node {
  const bonuses = props.bonuses ?? [];
  const {
    showSalesBonuses,
    showRetentionBonuses,
    showPerUnitTargetBonuses,
    showSDRBonuses,
    agentRole,
    showBillingSpecialistBonuses,
  } = props;

  const eligibleBonuses = bonuses.filter(
    (bonus) =>
      (showSalesBonuses &&
        (bonus.commissionBonusType === BonusType.ClosingRatio ||
          bonus.commissionBonusType === BonusType.DialsPerSale)) ||
      (showRetentionBonuses && bonus.commissionBonusType === BonusType.SaveRatio) ||
      (showPerUnitTargetBonuses &&
        bonus.commissionBonusType === BonusType.ManagerSalesPerUnitTarget) ||
      bonus.commissionBonusType === BonusType.ManagerSalesClosingRatio ||
      bonus.commissionBonusType === BonusType.ManagerSalesDialsPerSale ||
      (showSDRBonuses &&
        (bonus.commissionBonusType === BonusType.ManagerRepCommissionTarget ||
          bonus.commissionBonusType === BonusType.SDRTeamPerformance)) ||
      (showBillingSpecialistBonuses &&
        (bonus.commissionBonusType === BonusType.FirstPay ||
          bonus.commissionBonusType === BonusType.SecondPayPlus)),
  );

  return (
    <Row className="bonus-section-root" gutter={24} wrap={true}>
      {eligibleBonuses.length > 0 ? (
        <Col className="bonus-section-bonuses" span={24}>
          {eligibleBonuses
            .sort(
              (a, b) => a.commissionBonusType - b.commissionBonusType || a.dealType - b.dealType,
            )
            .map((bonus, index) => createBonusBlock(bonus, index.toString(), agentRole))}
        </Col>
      ) : (
        <Col className="bonus-section-empty" span={24}>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Col>
      )}
    </Row>
  );
}

function createBonusBlock(bonus: Bonus, key: string, agentRole: RoleEnum): ?React.Node {
  switch (bonus.commissionBonusType) {
    case BonusType.ClosingRatio:
      return createClosingRatioBonusBlock(bonus, key);
    case BonusType.SaveRatio:
      return createSaveRatioBonusBlock(bonus, key);
    case BonusType.ManagerSalesPerUnitTarget:
      return createManagerSalesPerUnitTargetBonusBlock(bonus, key);
    case BonusType.ManagerRepCommissionTarget:
      return createManagerRepCommissionTargetBonusBlock(bonus, key);
    case BonusType.SDRTeamPerformance:
      return createSDRTeamPerformanceBonusBlock(bonus, key);
    case BonusType.ManagerSalesClosingRatio:
      return createManagerSalesClosingRatioBonusBlock(bonus, key);
    case BonusType.ManagerSalesDialsPerSale:
      return createManagerSalesDialsPerSaleBonusBlock(bonus, key);
    case BonusType.DialsPerSale:
      return createDialsPerSaleBonusBlock(bonus, key, agentRole);
    case BonusType.FirstPay:
      return firstPayBonusBlock(bonus, key);
    case BonusType.SecondPayPlus:
      return secondPayPlusBonusBlock(bonus, key);
    default:
      return null;
  }
}

function createClosingRatioBonusBlock(bonus: ClosingRatioBonus, key: string): ?React.Node {
  const {
    commissionBonusType,
    closingRatio,
    dealType,
    callDirection,
    numberOfInboundCalls,
    numberOfDealsSold,
    ratioPeriodStartDate,
    ratioPeriodEndDate,
    earnedTotal,
  } = bonus;
  return createCardBlock(
    `${formatDealType(dealType)} ${formatCallDirection(callDirection)} ${formatBonusType(
      commissionBonusType,
    )}`,
    key,
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem(
          'Period:',
          `${formatDateAsShortDate(ratioPeriodStartDate)} - ${formatDateAsShortDate(
            ratioPeriodEndDate,
          )}`,
        )}
        {createCardLineItem('Calls:', formatNumberForLocale(numberOfInboundCalls, 0))}
        {createCardLineItem('Deals Sold:', formatNumberForLocale(numberOfDealsSold, 0))}
        {dealType === DealTypes.MBI &&
          createCardLineItem('Earned Total:', formatNumberAsMoney(earnedTotal, true))}
      </div>
      {createCardProgressItem(closingRatio)}
    </>,
  );
}

function createDialsPerSaleBonusBlock(bonus: DialsPerSaleBonus, key: string): ?React.Node {
  const { commissionBonusType, numberOfDealsSold, dialsPerSale, numberOfDials } = bonus;

  return createCardBlock(
    `${formatBonusType(commissionBonusType)}`,
    key,
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem('Deals Sold:', formatNumberForLocale(numberOfDealsSold, 0))}
        {createCardLineItem('Dials Per Sale:', formatNumberForLocale(dialsPerSale, 2))}
        {createCardLineItem('Number of Dials:', numberOfDials)}
      </div>
    </>,
  );
}

function createSaveRatioBonusBlock(bonus: SaveRatioBonus, key: string): ?React.Node {
  const {
    commissionBonusType,
    saveRatio,
    numberOfInboundCalls,
    numberOfDealsSaved,
    ratioPeriodStartDate,
    ratioPeriodEndDate,
  } = bonus;
  return createCardBlock(
    `${formatBonusType(commissionBonusType)}`,
    key,
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem(
          'Period:',
          `${formatDateAsShortDate(ratioPeriodStartDate)} - ${formatDateAsShortDate(
            ratioPeriodEndDate,
          )}`,
        )}
        {createCardLineItem('Attempts:', formatNumberForLocale(numberOfInboundCalls, 0))}
        {createCardLineItem('Deals Saved:', formatNumberForLocale(numberOfDealsSaved, 0))}
      </div>
      {createCardProgressItem(saveRatio)}
    </>,
  );
}

function createManagerSalesPerUnitTargetBonusBlock(
  bonus: ManagerSalesPerUnitTargetBonus,
  key: string,
): ?React.Node {
  const { commissionBonusType, dealType, perUnitTargetAmount } = bonus;
  return createCardBlock(
    `${formatDealType(dealType)} ${formatBonusType(commissionBonusType)}`,
    key,
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem('Channel Average:', formatNumberAsMoney(perUnitTargetAmount, true))}
      </div>
    </>,
  );
}

function createManagerRepCommissionTargetBonusBlock(
  bonus: ManagerRepCommissionTargetBonus,
  key: string,
): ?React.Node {
  const { commissionBonusType, repCommissionTargetAmount } = bonus;
  return createCardBlock(
    `${formatBonusType(commissionBonusType)}`,
    key,
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem(
          'Avg. Rep Commission:',
          formatNumberAsMoney(repCommissionTargetAmount, true),
        )}
      </div>
    </>,
  );
}

function createSDRTeamPerformanceBonusBlock(
  bonus: SDRTeamPerformanceBonus,
  key: string,
): ?React.Node {
  const { closingPercentage, closingPercentageBonusAmount } = bonus;
  return (
    <>
      {createCardBlock(
        'Closing % Bonus',
        key + 'closing',
        <>
          <div className="bonus-section-line-items">
            {createCardLineItem('Avg. Closing %:', formatNumberAsPercentage(closingPercentage))}
            {createCardLineItem('Bonus:', formatNumberAsMoney(closingPercentageBonusAmount, true))}
          </div>
          {createCardProgressItem(closingPercentage)}
        </>,
      )}
    </>
  );
}

function createManagerSalesClosingRatioBonusBlock(
  bonus: ManagerSalesClosingRatioBonus,
  key: string,
): ?React.Node {
  const { attainment, earnedTotal, dealType, targetClosingRatioPercetange } = bonus;
  let label;
  switch (dealType) {
    case DealTypes.Digital:
      label = 'Digital CR Bonus';
      break;
    case DealTypes.DirectMail:
      label = 'Direct Mail CR Bonus';
      break;
    case DealTypes.Television:
      label = 'TV CR Bonus';
      break;
    default:
      label = 'Closing Ratio Bonus';
  }

  return createCardBlock(
    label,
    key,
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem('Team CR %:', formatNumberAsPercentage(attainment, true))}
        {createCardLineItem(
          'Target CR %:',
          formatNumberAsPercentageWithDecimals(targetClosingRatioPercetange, true),
        )}
        {createCardLineItem('Bonus Earned:', formatNumberAsMoney(earnedTotal, true))}
      </div>
    </>,
  );
}

function createManagerSalesDialsPerSaleBonusBlock(
  bonus: ManagerSalesDialsPerSaleBonus,
  key: string,
): ?React.Node {
  const { attainment, targetNumberOfDialsPerSale, earnedTotal } = bonus;
  return createCardBlock(
    'Dials Per Sale Bonus',
    key + 'dps',
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem('Team Dials Per Sale:', formatNumberForLocale(attainment, 2))}
        {createCardLineItem(
          'Target Dials Per Sale:',
          formatNumberForLocale(targetNumberOfDialsPerSale, 2),
        )}
        {createCardLineItem('Bonus Earned:', formatNumberAsMoney(earnedTotal, true))}
      </div>
    </>,
  );
}

function firstPayBonusBlock(bonus: FirstPayBonus, key: string): ?React.Node {
  const { numberOfPayments, earnedTotal } = bonus;
  return createCardBlock(
    'First Bonus',
    key + 'dps',
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem('Number of Payments:', numberOfPayments)}
        {createCardLineItem('Total:', formatNumberAsMoney(earnedTotal, true))}
      </div>
    </>,
  );
}

function secondPayPlusBonusBlock(bonus: SecondPayPlusBonus, key: string): ?React.Node {
  const { numberOfPayments, earnedTotal } = bonus;
  return createCardBlock(
    'Second Pay Plus Bonus',
    key + 'dps',
    <>
      <div className="bonus-section-line-items">
        {createCardLineItem('Number of Payments:', numberOfPayments)}
        {createCardLineItem('Total:', formatNumberAsMoney(earnedTotal, true))}
      </div>
    </>,
  );
}

function createCardBlock(label: string, key: string, content: React.Node): React.Node {
  return (
    <Card className="bonus-section-card" title={label} bordered={true} key={key}>
      {content}
    </Card>
  );
}

function createCardLineItem(label: string, value: string): React.Node {
  return (
    <div className="bonus-section-line-item">
      <div className="bonus-section-line-label">{label}</div>
      <div className="bonus-section-line-value">{value}</div>
    </div>
  );
}

function createCardProgressItem(value: number) {
  const percent = truncateNumber(value * 100, 1);
  return (
    <div className="bonus-section-progress-item">
      <Progress type="circle" percent={percent} status="normal" width={80} />
    </div>
  );
}

export default BonusSection;
