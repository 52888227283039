/* @flow */
import '../../css/dashboard/CommissionTable.css';
import * as React from 'react';
import { Table } from 'antd';
import { formatDateAsShortDate, formatNumberAsMoney } from '../../utils/formatter';
import SearchModalButton from '../shared/SearchModalButton';
import type { SalesDevelopmentCommission } from '../../models/deals/SalesDevelopmentCommission';

type Props = $ReadOnly<{
  deals: ?Array<SalesDevelopmentCommission>,
  total: ?number,
  minScrollHeight: number,
}>;

SalesDevelopmentCommissionsTable.defaultProps = {
  minScrollHeight: 240,
};

function SalesDevelopmentCommissionsTable(props: Props): React.Node {
  const { deals, minScrollHeight } = props;
  const scroll = { y: minScrollHeight };

  return (
    <Table
      bordered
      columns={getColumns()}
      dataSource={deals}
      size="small"
      pagination={false}
      scroll={scroll}
      footer={(deals) => getFooter(deals, props)}
      rowKey={(deal) => deal.id}
    />
  );
}

function getColumns(): any {
  return [
    {
      title: 'Customer Number',
      dataIndex: 'moxyCustomerNumber',
      editable: false,
      render: (value: string): React.Node => <SearchModalButton query={value} label={value} />,
      sorter: (a, b) => a.moxyCustomerNumber.localeCompare(b.moxyCustomerNumber),
    },
    {
      title: 'Contract Number',
      dataIndex: 'contractNumber',
      editable: false,
      render: (value: string): React.Node => <SearchModalButton query={value} label={value} />,
      sorter: (a, b) => a.contractNumber.localeCompare(b.contractNumber),
    },
    {
      title: 'Sold Date',
      dataIndex: 'soldDate',
      editable: false,
      render: (value) => formatDateAsShortDate(value, true, false),
      sorter: (a, b) => new Date(a.soldDate) - new Date(b.soldDate),
      align: 'center',
      defaultSortOrder: 'ascend',
      width: 175,
    },
    {
      title: 'Canceled Date',
      dataIndex: 'cancelledDate',
      editable: false,
      render: (value) => formatDateAsShortDate(value, true, true),
      sorter: (a, b) => new Date(a.cancelledDate) - new Date(b.cancelledDate),
      align: 'center',
      defaultSortOrder: 'ascend',
      width: 175,
    },
    {
      title: 'Earned this Period',
      dataIndex: 'earnedTotal',
      editable: false,
      render: (value) => formatNumberAsMoney(value, true),
      sorter: (a, b) => a.earnedTotal - b.earnedTotal,
      align: 'right',
      width: 175,
    },
  ];
}

function getFooter(deals: Array<SalesDevelopmentCommission>, props: Props): React.Node {
  const { total } = props;
  const formattedTotal = formatNumberAsMoney(total, true);

  return (
    <div className="commission-table-footer">
      <div className="commission-table-footer-cell commission-table-footer-cell-label">
        Total Earned this Period:
      </div>
      <div className="commission-table-footer-cell commission-table-footer-cell-value">
        {formattedTotal}
      </div>
    </div>
  );
}

export default SalesDevelopmentCommissionsTable;
