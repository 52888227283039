/* @flow */
import * as React from 'react';
import { Button } from 'antd';
import { createAndDownloadCsv } from '../../utils/downloader';
import {
  formatBooleanAsString,
  formatDateAsShortDate,
  formatDateForFileName,
  formatNumberAsMoney,
} from '../../utils/formatter';
import { formatDealType, formatSalesCommissionStatus } from '../../utils/enumFormatter';
import { size } from 'lodash';
import type { Agent } from '../../models/Agent';
import type { PayPeriod } from '../../models/PayPeriod';
import type { SalesCommission } from '../../models/deals/SalesCommission';
import type { SalesDevelopmentCommission } from '../../models/deals/SalesDevelopmentCommission';

type Props = $ReadOnly<{
  agent: ?Agent,
  buttonText?: string,
  deals: ?Array<SalesCommission>,
  juniorDeals: ?Array<SalesDevelopmentCommission>,
  payPeriod: PayPeriod,
}>;

SalesCommissionDownloadCsvButton.defaultProps = {
  buttonText: 'Download',
};

function SalesCommissionDownloadCsvButton(props: Props): React.Node {
  const { buttonText } = props;
  return (
    <Button onClick={() => onDownloadClick(props)} type="link">
      {buttonText}
    </Button>
  );
}

function getSalesCommissionFields(): Array<any> {
  return [
    {
      label: 'Customer Number',
      value: 'moxyCustomerNumber',
    },
    {
      label: 'Contract Number',
      value: 'contractNumber',
    },
    {
      label: 'Channel',
      value: (row: any): string => formatDealType(row.dealType),
    },
    {
      label: 'Status',
      value: (row: any): string => formatSalesCommissionStatus(row.salesCommissionStatus, false),
    },
    {
      label: 'Sold Date',
      value: (row: any): string => formatDateAsShortDate(row.soldDetails.soldDate, true, false),
    },
    {
      label: 'Canceled Date',
      value: (row: any): string =>
        formatDateAsShortDate(row.cancelledDetails?.cancelledDate, true, true),
      default: '',
    },
    {
      label: 'Funded Date',
      value: (row: any): string => formatDateAsShortDate(row.fundedDetails?.fundedDate, true, true),
      default: '',
    },
    {
      label: 'Payment Method',
      value: 'soldDetails.paymentType',
    },
    {
      label: 'Full Pay?',
      value: (row: any): string => formatBooleanAsString(row.soldDetails.isFullPay),
    },
    {
      label: 'Split Deal?',
      value: (row: any): string => formatBooleanAsString(row.isSplitDeal),
    },
    {
      label: 'Activation Fee',
      value: (row: any): string => formatNumberAsMoney(row.activationFee, true),
    },
    {
      label: 'Commission Total',
      value: (row: any): string => formatNumberAsMoney(row.rawTotal, true),
    },
    {
      label: 'Holdback',
      value: (row: any): string => formatNumberAsMoney(row.holdbackTotal, true),
    },
    {
      label: 'Finance Term Contr.',
      value: (row: any): string => formatNumberAsMoney(row.financeTermAmount, true),
    },
    {
      label: 'Retail Deviation Contr.',
      value: (row: any): string => formatNumberAsMoney(row.retailDeviationAmount, true),
    },
    {
      label: 'Elite Contr.',
      value: (row: any): string => formatNumberAsMoney(row.eliteActivationAmount, true),
    },
    {
      label: 'Closing Ratio Contr.',
      value: (row: any): string => formatNumberAsMoney(row.closingRatioBonusAmount, true),
    },
    {
      label: 'Earned this Period',
      value: (row: any): string => formatNumberAsMoney(row.earnedTotal, true),
    },
    {
      label: 'Outstanding',
      value: (row: any): string => formatNumberAsMoney(row.outstandingTotal, true),
    },
  ];
}

function getSalesFileName(agent: ?Agent, payPeriod: PayPeriod): string {
  const startDate = formatDateForFileName(payPeriod.startDate);
  const endDate = formatDateForFileName(payPeriod.endDate);
  return `Agent ${
    agent?.fullName ?? ''
  } Sales Commissions for Period ${startDate} to ${endDate}.csv`;
}

function getSalesDevelopmentCommissionFields(): Array<any> {
  return [
    {
      label: 'Customer Number',
      value: 'moxyCustomerNumber',
    },
    {
      label: 'Contract Number',
      value: 'contractNumber',
    },
    {
      label: 'Sold Date',
      value: (row: any): string => formatDateAsShortDate(row.soldDate, true, true),
    },
    {
      label: 'Earned this Period',
      value: (row: any): string => formatNumberAsMoney(row.earnedTotal, true),
    },
  ];
}

function getSalesDevelopmentFileName(agent: ?Agent, payPeriod: PayPeriod): string {
  const startDate = formatDateForFileName(payPeriod.startDate);
  const endDate = formatDateForFileName(payPeriod.endDate);
  return `Agent ${
    agent?.fullName ?? ''
  } Sales Development Commissions for Period ${startDate} to ${endDate}.csv`;
}

function onDownloadClick(props: Props) {
  let { deals, juniorDeals, agent, payPeriod } = props;
  let fileName = getSalesFileName(agent, payPeriod);
  let fields = getSalesCommissionFields();

  if (size(deals) > 0) {
    createAndDownloadCsv(deals, fields, fileName);
  }

  if (size(juniorDeals) > 0) {
    fileName = getSalesDevelopmentFileName(agent, payPeriod);
    fields = getSalesDevelopmentCommissionFields();
    createAndDownloadCsv(juniorDeals, fields, fileName);
  }
}

export default SalesCommissionDownloadCsvButton;
