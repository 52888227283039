/* @flow */
import '../../css/dashboard/CommissionsSection.css';
import * as React from 'react';
import { DealCommissionTypes } from '../../enums/DealCommissionTypeEnum';
import { Empty, Space } from 'antd';
import { SearchModalContext } from '../../context/SearchModalContext';
import { convertDateTimeOffsetUTCToCST } from '../../utils/formatter';
import { filter, find, isEmpty, isNil } from 'lodash';
import { useCallback, useState } from 'react';
import ManagerCommissionDownloadCsvButton from './ManagerCommissionsDownloadCsvButton';
import ManagerCommissionTabs from './ManagerCommissionTabs.jsx';
import RetentionCommissionDownloadCsvButton from './RetentionCommissionsDownloadCsvButton';
import RetentionCommissionTabs from './RetentionCommissionTabs.jsx';
import SalesCommissionDownloadCsvButton from './SalesCommissionsDownloadCsvButton';
import SalesCommissionsTabs from './SalesCommissionTabs';
import SalesDevelopmentCommissionTabs from './SalesDevelopmentCommissionTabs';
import SearchModal from '../shared/SearchModal';
import usePayPeriods from '../../hooks/services/pay-period/usePayPeriods';
import type { Agent } from '../../models/Agent';
import type { AgentCommissionTotals } from '../../models/totals/AgentCommissionTotals';
import type { DealCommission } from '../../models/deals/DealCommission';
import type { ManagerCommission } from '../../models/managers/ManagerCommission';
import type { RetentionDealCommission } from '../../models/deals/RetentionDealCommission';
import type { SalesCommission } from '../../models/deals/SalesCommission';
import type { SalesDevelopmentCommission } from '../../models/deals/SalesDevelopmentCommission';

type Props = $ReadOnly<{
  totals: ?AgentCommissionTotals,
  managerCommissions: Array<ManagerCommission>,
  dealCommissions: Array<DealCommission>,
  agent: ?Agent,
  payPeriodId: ?number,
}>;

function CommissionsSection(props: Props): React.Node {
  const { totals, managerCommissions, dealCommissions, agent, payPeriodId } = props;

  if (isNil(totals) || (isEmpty(managerCommissions) && isEmpty(dealCommissions))) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const [visible, setVisible] = useState(false);
  const [query, setQuery] = useState('');
  const updateModal = useCallback((v: boolean, q: string) => {
    setVisible(v);
    setQuery(q);
  }, []);

  const payPeriods = usePayPeriods();
  const selectedPayPeriod = find(payPeriods, (payPeriod) => payPeriod.id === payPeriodId);

  const salesCommissions = getSaleDeals(dealCommissions);
  const salesDevelopmentCommissions = getSalesDevelopmentDeals(dealCommissions);
  const retentionCommissions = getRetentionDeals(dealCommissions);

  const managerDownloadButton = (
    <ManagerCommissionDownloadCsvButton
      agent={agent}
      buttonText="Download all as csv"
      deals={managerCommissions}
      payPeriod={selectedPayPeriod}
    />
  );

  const salesDownloadButton = (
    <SalesCommissionDownloadCsvButton
      agent={agent}
      buttonText="Download all as csv"
      deals={salesCommissions}
      juniorDeals={salesDevelopmentCommissions}
      payPeriod={selectedPayPeriod}
    />
  );

  const retentionDownloadButton = (
    <RetentionCommissionDownloadCsvButton
      agent={agent}
      buttonText="Download all as csv"
      deals={retentionCommissions}
      payPeriod={selectedPayPeriod}
    />
  );

  return (
    <SearchModalContext.Provider value={{ updateModal }}>
      <Space className="commissions-section-root" direction="vertical" size="large">
        {managerCommissions.length > 0 && (
          <div>
            <h3>Manager Commission</h3>
            <ManagerCommissionTabs
              payPeriodId={payPeriodId || 0}
              commissions={managerCommissions}
              total={totals?.commissionManagerTotals}
              extraContent={managerDownloadButton}
            />
          </div>
        )}
        {salesDevelopmentCommissions.length > 0 && (
          <div>
            <h3>Sales Development Commission</h3>
            <SalesDevelopmentCommissionTabs
              deals={salesDevelopmentCommissions}
              total={totals?.commissionDealTotals}
              extraContent={salesDownloadButton}
            />
          </div>
        )}
        {salesCommissions.length > 0 && (
          <div>
            <h3>Sales Commission</h3>
            <SalesCommissionsTabs
              deals={salesCommissions}
              total={totals?.commissionDealTotals}
              extraContent={salesDownloadButton}
            />
          </div>
        )}
        {retentionCommissions.length > 0 && (
          <div>
            <h3>Retention Commission</h3>
            <RetentionCommissionTabs
              commissions={retentionCommissions}
              total={totals?.commissionRetentionTotals}
              extraContent={retentionDownloadButton}
            ></RetentionCommissionTabs>
          </div>
        )}
        <SearchModal
          payPeriodId={payPeriodId}
          agentId={agent?.id}
          visible={visible}
          query={query}
        />
      </Space>
    </SearchModalContext.Provider>
  );
}

function getSaleDeals(deals: ?Array<DealCommission>): Array<SalesCommission> {
  return filter(deals, (deal) => {
    if (deal.commissionType === DealCommissionTypes.Sales) {
      deal.soldDetails.soldDate = convertDateTimeOffsetUTCToCST(deal.soldDetails.soldDate);
      return true;
    }
    return false;
  });
}

function getSalesDevelopmentDeals(
  deals: ?Array<DealCommission>,
): Array<SalesDevelopmentCommission> {
  return filter(deals, (deal) => {
    if (deal.commissionType === DealCommissionTypes.SalesDevelopment) {
      deal.soldDate = convertDateTimeOffsetUTCToCST(deal.soldDate);
      return true;
    }
    return false;
  });
}

function getRetentionDeals(deals: ?Array<DealCommission>): Array<RetentionDealCommission> {
  return filter(deals, (deal) => {
    if (deal.commissionType === DealCommissionTypes.Retention) {
      deal.soldDate = convertDateTimeOffsetUTCToCST(deal.soldDate);
      return true;
    }
    return false;
  });
}

export default CommissionsSection;
