/* @flow */
import '../../css/pages/Dashboard.css';
import * as React from 'react';
import { BonusType } from '../../enums/BonusTypeEnum';
import { DealCommissionTypes } from '../../enums/DealCommissionTypeEnum';
import { Empty, Spin } from 'antd';
import { RoleEnum } from '../../enums/RoleEnum';
import { TeamCommissionTypes } from '../../enums/TeamCommissionTypeEnum';
import { flatMap, isNaN, isNil, toNumber } from 'lodash';
import {
  formatDateAsShortDate,
  formatDateAsTime,
  formatTeamMemberships,
} from '../../utils/formatter';
import { useCallback, useEffect, useState } from 'react';
import { useNavigationType, useSearchParams } from 'react-router-dom';
import AdjustmentsTable from '../dashboard/AdjustmentsTable';
import AgentSelector from '../shared/AgentSelector';
import BonusSection from '../dashboard/BonusSection';
import CommissionsSection from '../dashboard/CommissionsSection';
import IncentiveTable from '../dashboard/RepIncentivesTable';
import PayPeriodSelector from '../shared/PayPeriodSelector';
import PaymentTotals from '../dashboard/PaymentTotals';
import Section from '../shared/Section';
import SubHeader from '../shared/SubHeader';
import useAgentCommission from '../../hooks/services/agent/useAgentCommission';
import useAgentIncentives from '../../hooks/services/incentive/useAgentIncentives';
import useIncentive from '../../hooks/services/incentive/useIncentive';
import useMyRole from '../../hooks/services/role/useMyRole';
import type { Agent } from '../../models/Agent';

function Dashboard(): React.Node {
  // Navigation
  const [searchParams, setSearchParams] = useSearchParams();
  const navigationType = useNavigationType();
  const queryAgentId = searchParams.get('agent');
  const queryPayPeriodId = searchParams.get('payPeriod');
  const defaultAgentId =
    !isNil(queryAgentId) || !isNil(queryPayPeriodId) ? toNumber(queryAgentId) : null;
  const defaultPayPeriodId =
    !isNil(queryPayPeriodId) || !isNil(queryAgentId) ? toNumber(queryPayPeriodId) : null;

  // States
  const [agent, setAgent] = useState(null);
  const [payPeriodId, setPayPeriodId] = useState(
    isNaN(defaultPayPeriodId) ? 0 : defaultPayPeriodId,
  );

  // Functions
  const getAgentId = (): number => {
    const agentId = isNaN(defaultAgentId) || defaultAgentId === null ? 0 : defaultAgentId;
    return agent?.id ?? agentId;
  };

  // Queries
  const { isLoading, agentCommission, agentCommissionTotals } = useAgentCommission(
    getAgentId(),
    payPeriodId,
  );
  const { isLoadingIncentives, incentives } = useIncentive();
  const { isLoadingAgentIncentives, incentiveReview } = useAgentIncentives(payPeriodId ?? 0);

  // Callbacks
  const onAgentChange = useCallback(
    (value: ?Agent) => {
      const validPayPeriodId = payPeriodId ?? 0;
      setAgent(value);
      if (!isNil(value) && validPayPeriodId > 0) {
        setSearchParams({ agent: value?.id, payPeriod: payPeriodId });
      }
    },
    [payPeriodId],
  );

  const onPayPeriodChange = useCallback(
    (value: ?number) => {
      setPayPeriodId(value);
      const validAgentId = getAgentId() ?? 0;
      if (validAgentId > 0 && !isNil(value)) {
        setSearchParams({ agent: getAgentId(), payPeriod: value });
      }
    },
    [agent],
  );

  // Effects
  useEffect(() => {
    if (navigationType !== 'POP' || isNil(defaultAgentId) || isNil(defaultPayPeriodId)) {
      return;
    }

    setAgent(null);
    setPayPeriodId(defaultPayPeriodId);
  }, [defaultAgentId, defaultPayPeriodId, navigationType]);

  // Render
  const hasManagerCommissions =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.managerCommissions &&
    agentCommission.managerCommissions.length > 0;

  const hasSalesCommissions =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.dealCommissions &&
    agentCommission.dealCommissions.filter(
      (commission) =>
        commission.commissionType === DealCommissionTypes.Sales ||
        commission.commissionType === DealCommissionTypes.SalesDevelopment,
    ).length > 0;

  const hasSalesDevelopmentCommissions =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.dealCommissions &&
    agentCommission.dealCommissions.filter(
      (commission) => commission.commissionType === DealCommissionTypes.SalesDevelopment,
    ).length > 0;

  const hasRetentionCommissions =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.dealCommissions &&
    agentCommission.dealCommissions.filter(
      (commission) => commission.commissionType === DealCommissionTypes.Retention,
    ).length > 0;

  const hasOnboardingCommissions =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.agent?.teamMemberships &&
    agentCommission.agent?.teamMemberships.filter(
      (teamMemberships) =>
        teamMemberships.team?.teamCommissionType === TeamCommissionTypes.Onboarding,
    ).length > 0;

  const hasPerUnitTargetBonuses =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.bonuses &&
    agentCommission.bonuses.filter(
      (bonus) => bonus.commissionBonusType === BonusType.ManagerSalesPerUnitTarget,
    ).length > 0;

  const hasSDRBonuses =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.bonuses &&
    agentCommission.bonuses.filter(
      (bonus) =>
        bonus.commissionBonusType === BonusType.ManagerRepCommissionTarget ||
        bonus.commissionBonusType === BonusType.SDRTeamPerformance,
    ).length > 0;

  const hasBillingSpecialistBonuses =
    agentCommission !== null &&
    agentCommission !== undefined &&
    agentCommission.bonuses &&
    agentCommission.bonuses.filter(
      (bonus) =>
        bonus.commissionBonusType === BonusType.FirstPay ||
        bonus.commissionBonusType === BonusType.SecondPayPlus,
    ).length > 0;

  const myRole = useMyRole();

  const isManagerOrAdmin = () =>
    myRole?.role.name === RoleEnum.Admin ||
    myRole?.role.name === RoleEnum.RetentionManager ||
    myRole?.role.name === RoleEnum.Manager;

  const teamIncentives = !isNil(incentiveReview)
    ? incentiveReview.teamIncentivesReviews
    : flatMap(null);

  const hasTeamName = agentCommission?.agent?.teamMemberships?.[0]?.team?.teamName !== undefined;

  return (
    <div className="dashboard-root">
      <SubHeader
        headerLabel={'Commissions Dashboard'}
        primarySelectorSeparator={'for'}
        primarySelectorLabel={'Commissions Period:'}
        primarySelector={
          <PayPeriodSelector
            payPeriodId={payPeriodId}
            onChange={onPayPeriodChange}
            showStatus={true}
          />
        }
        secondarySelectorSeparator={'for'}
        secondarySelectorLabel={'Agent:'}
        secondarySelector={
          payPeriodId !== null &&
          payPeriodId !== undefined &&
          payPeriodId > 0 && (
            <AgentSelector
              agentId={getAgentId()}
              defaultExcludeInactive={isNil(queryAgentId) && isNil(queryPayPeriodId)}
              onChange={onAgentChange}
              showStatus={true}
              useImpersonation={true}
              showErrorMessage={false}
              showExcludeInactiveCheckbox={true}
              payPeriodId={payPeriodId}
              tooltipErrorMessage={''}
              useTeamGrouping={false}
            />
          )
        }
      />
      {!hasManagerCommissions &&
      !hasSalesCommissions &&
      !hasSalesDevelopmentCommissions &&
      !hasRetentionCommissions &&
      !hasBillingSpecialistBonuses ? (
        <div className="centered-empty-section">
          {isLoading ? <Spin /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        </div>
      ) : (
        <>
          <Section
            headerLabel={'SUMMARY'}
            contents={[
              <div key="summary-header">
                <div>
                  <div key="teams">
                    <h3>Team</h3>
                    {!hasTeamName ? (
                      <span>{'N/A'}</span>
                    ) : (
                      <span>
                        {formatTeamMemberships(agentCommission?.agent.teamMemberships ?? [])}
                      </span>
                    )}
                  </div>
                  <div className="last-updated">
                    <span>
                      Last Updated: {formatDateAsShortDate(agentCommission?.updatedDate)}
                      {' at '}
                      {formatDateAsTime(agentCommission?.updatedDate)}
                    </span>
                  </div>
                </div>
                {isManagerOrAdmin() && agentCommission?.isApproved ? (
                  <div className="approved-date">
                    <span>Approved by: {agentCommission.approvedBy}</span>
                    <span>
                      on: {formatDateAsShortDate(agentCommission.approvedDate)}
                      {' at '}
                      {formatDateAsTime(agentCommission.approvedDate)}
                    </span>
                  </div>
                ) : (
                  ''
                )}
              </div>,
              <PaymentTotals
                key={'paymentTotalsSection'}
                agentCommissionTotals={agentCommissionTotals}
                showManagerTotals={hasManagerCommissions}
                showSalesTotals={hasSalesCommissions}
                showSalesDevelopmentTotals={hasSalesDevelopmentCommissions}
                showRetentionTotals={hasRetentionCommissions}
                showOnboardingTotals={hasOnboardingCommissions}
                showBillingSpecialistTotals={hasBillingSpecialistBonuses}
              />,
            ]}
            isLoading={isLoading}
          />
          {
            <Section
              headerLabel={'CLOSING RATE'}
              contents={[
                <BonusSection
                  key={'bonusesSection'}
                  bonuses={agentCommission?.bonuses}
                  showSalesBonuses={hasSalesCommissions}
                  showRetentionBonuses={hasRetentionCommissions}
                  showPerUnitTargetBonuses={hasPerUnitTargetBonuses}
                  showSDRBonuses={hasSDRBonuses}
                  showBillingSpecialistBonuses={hasBillingSpecialistBonuses}
                  agentRole={myRole?.role.name}
                />,
              ]}
              isLoading={isLoading}
            />
          }
          {hasSDRBonuses && (
            <Section
              headerLabel={'SDR PERFORMANCE'}
              contents={[
                <BonusSection
                  key={'bonusesSection'}
                  bonuses={agentCommission?.bonuses}
                  showSalesBonuses={false}
                  showRetentionBonuses={false}
                  showPerUnitTargetBonuses={false}
                  showSDRBonuses={hasSDRBonuses}
                  showBillingSpecialistBonuses={hasBillingSpecialistBonuses}
                  agentRole={myRole?.role.name}
                />,
              ]}
              isLoading={isLoading}
            />
          )}
          <Section
            headerLabel={'COMMISSIONS'}
            contents={[
              <CommissionsSection
                key={'commissionsSection'}
                totals={agentCommissionTotals}
                managerCommissions={agentCommission?.managerCommissions || []}
                dealCommissions={agentCommission?.dealCommissions || []}
                agent={agent}
                payPeriodId={payPeriodId}
              />,
            ]}
            isLoading={isLoading}
          />
          <Section
            headerLabel={'CORRECTIONS AND ADJUSTMENTS'}
            contents={[
              <AdjustmentsTable
                key={'adjustmentsTableSection'}
                adjustments={agentCommission?.adjustments}
                adjustmentTotal={agentCommissionTotals?.commissionAdjustmentTotals.total}
                minScrollHeight={390}
              />,
            ]}
            isLoading={isLoading}
          />

          {getAgentId() > 0 && (
            <Section
              headerLabel={'INCENTIVES'}
              contents={[
                <IncentiveTable
                  className="incentive-table"
                  key={'incentiveTable'}
                  incentives={incentives}
                  agentIncentives={teamIncentives
                    .flatMap((x) => x.agentIncentives ?? [])
                    .filter((x) => x?.masterAgentId === getAgentId())}
                />,
              ]}
              isLoading={isLoading || isLoadingIncentives || isLoadingAgentIncentives}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
