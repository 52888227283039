/* @flow */
import { format, toZonedTime } from 'date-fns-tz';
import { toNumber } from 'lodash';
import Decimal from 'decimal.js';
import moment from 'moment/moment';
import type { Incentive } from '../models/incentives/Incentive';
import type { TeamMembership } from '../models/TeamMembership';

export function formatDateAsShortDate(
  utcDateString: ?string,
  useFullYear: boolean = false,
  convertToUTC: boolean = false,
): string {
  const dateString = utcDateString ?? '';
  if (!dateString) return '';

  const dateObject = convertToUTC
    ? new Date(dateString)
    : new Date(dateString.length === 25 ? dateString.slice(0, -6) : dateString);
  const month = convertToUTC ? dateObject.getUTCMonth() + 1 : dateObject.getMonth() + 1;
  const day = convertToUTC ? dateObject.getUTCDate() : dateObject.getDate();
  let year = convertToUTC ? dateObject.getUTCFullYear() : dateObject.getFullYear();
  year = useFullYear ? year : year.toString().slice(2);

  return `${month}/${day}/${year}`;
}

export function formatDateAsTime(utcDateString: ?string, convertToUTC: boolean = false): string {
  const dateString = utcDateString ?? '';
  if (!dateString) {
    return '';
  }

  const dateObject = new Date(dateString);

  try {
    return convertToUTC
      ? `${dateObject.toLocaleTimeString('en-us', { timeZone: 'UTC' })} UTC`
      : `${dateObject.toLocaleTimeString('en-us', { timeZone: 'CST' })} CST`;
  } catch {
    return dateObject.toLocaleTimeString();
  }
}

export function formatDateForFileName(
  dateToConvert: string,
  convertToUTC: boolean = false,
): string {
  const dateString = dateToConvert ?? '';
  if (!dateString) return '';

  const date = convertToUTC
    ? new Date(dateString)
    : new Date(dateString.length === 25 ? dateString.slice(0, -6) : dateString);

  let month = convertToUTC ? date.getUTCMonth() + 1 : date.getMonth() + 1;
  let day = convertToUTC ? date.getUTCDate() : date.getDate();
  const year = convertToUTC ? date.getUTCFullYear() : date.getFullYear();

  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  return `${month}-${day}-${year}`;
}

export function formatDateWithTime(date: string): string {
  return moment(date).format('MM/DD/YYYY h:mm A');
}

export function formatContestPeriod(dates: string): string {
  return dates.split('-').map(formatDateWithTime).join(' - ');
}

export function formatNumberAsMoney(value: ?number, includeDollarSign: boolean = false): string {
  value = value ?? 0;
  value = !isNaN(value) ? value : 0;

  const formattedNumber = formatNumberForLocale(value, 2);
  const prefix = includeDollarSign ? '$' : '';

  return !value || value >= 0
    ? `${prefix}${formattedNumber}`
    : `-${prefix}${formattedNumber.slice(1)}`;
}

export function convertDateTimeOffsetUTCToCST(dateString: string): string {
  const timeZone = 'America/Chicago';
  const zonedDate = toZonedTime(new Date(dateString), timeZone);
  return format(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone });
}

export function formatNumberForLocale(
  value: number,
  numberOfDecimalPlaces: number = 0,
  locale: string = 'en-US',
): string {
  return value.toLocaleString(locale, {
    maximumFractionDigits: numberOfDecimalPlaces,
    minimumFractionDigits: numberOfDecimalPlaces,
  });
}

export function formatNumberAsPercentage(
  value: number,
  isZeroToOneRepresentation: boolean = true,
  useNewDecimalHandling: boolean = false,
): string {
  if (useNewDecimalHandling) {
    var decimal = new Decimal(value);
    var decimalPercentage = decimal.times(100);

    return `${decimalPercentage.toFixed(2)}%`;
  }

  if (isZeroToOneRepresentation) value = value * 100;
  if (getNumberOfDecimalPlaces(value) > 2) value = truncateNumber(value, 2);
  return `${toNumber(value)}%`;
}

export function formatNumberAsPercentageWithDecimals(
  value: number,
  isZeroToOneRepresentation: boolean = true,
  numberOfDecimals: number = 2,
): string {
  if (isZeroToOneRepresentation) value = value * 100;
  return `${value.toFixed(numberOfDecimals)}%`;
}

export function truncateNumber(value: number, numberOfDecimalPlaces: number = 0): number {
  const valueAsString = value.toString();
  const indexOfDecimalPlace = valueAsString.indexOf('.');

  if (indexOfDecimalPlace < 0) return value;

  let substringLength = indexOfDecimalPlace + numberOfDecimalPlaces;
  if (numberOfDecimalPlaces > 0) substringLength++;

  const valueAsStringTruncated = valueAsString.substring(0, substringLength);
  return toNumber(valueAsStringTruncated);
}

export function formatBooleanAsString(value: boolean): string {
  return value ? 'Yes' : 'No';
}

function getNumberOfDecimalPlaces(value: number): number {
  if (Math.floor(value.valueOf()) === value.valueOf()) return 0;
  return value.toString().split('.')[1].length || 0;
}

export function formatFullName(firstName: string, lastName: string): string {
  return `${firstName} ${lastName}`;
}

export function formatTeamMemberships(memberships: Array<TeamMembership>): string {
  if (!memberships) {
    return '';
  }

  return memberships
    .map((membership) => membership.team.teamName)
    .sort()
    .join(', ');
}

export function nth(n: number): string {
  return ['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th';
}

export function formatNthWeekOfMonth(value: any): string {
  const weekOfMonth = value.week() - moment(value).startOf('month').week() + 1;

  return `${weekOfMonth}${nth(weekOfMonth)} week of ${value.format('MMMM')}, ${value.format(
    'YYYY',
  )}`;
}

export function getIncentiveFieldLabel(
  selectedIncentiveId: number,
  incentives: Array<Incentive>,
): string {
  const incentive = incentives.find((incentive) => incentive.id === selectedIncentiveId);

  if (!incentive) return '';

  const incentiveField = incentive.incentiveFields.filter((value) => value.isMultiplier)[0] || null;
  if (incentiveField) {
    return 'Amount Per Unit';
  } else return 'Amount';
}
