/* @flow */
export const BonusType: {
  ClosingRatio: 1,
  SaveRatio: 2,
  ManagerSalesPerUnitTarget: 3,
  ManagerRepCommissionTarget: 4,
  SDRTeamPerformance: 5,
  DailySalesCount: 6,
  ManagerSalesClosingRatio: 7,
  ManagerSalesDialsPerSale: 8,
  DialsPerSale: 9,
  FirstPay: 10,
  SecondPayPlus: 11,
} = Object.freeze({
  ClosingRatio: 1,
  SaveRatio: 2,
  ManagerSalesPerUnitTarget: 3,
  ManagerRepCommissionTarget: 4,
  SDRTeamPerformance: 5,
  DailySalesCount: 6,
  ManagerSalesClosingRatio: 7,
  ManagerSalesDialsPerSale: 8,
  DialsPerSale: 9,
  FirstPay: 10,
  SecondPayPlus: 11,
});

export type BonusTypeEnum = $Values<typeof BonusType>;
